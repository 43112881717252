import "./css/home.css"
import Footer from "./Footer.js"
export default function Home() {
    return <div >
        


       
    


        <Footer />
    </div>

}